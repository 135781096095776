import { ApolloClient } from "@apollo/client";
import { Auth0UserProfile, WebAuth } from "auth0-js";
import axios from "axios";
import { useEnv } from "contexts";
import { SessionInterface } from "contexts/AuthContext";
import { useEffect, useMemo, useRef, useState } from "react";

const AUTH0_SCOPES = "openid profile email offline_access";

export interface LogoutOptions {
  returnTo?: string;
  resetOnboarding?: boolean;
  apolloClient?: ApolloClient<object>;
}

const useAuth0 = (
  onSessionChange: (session: SessionInterface) => void,
  onSessionInvalidate: (options?: LogoutOptions) => void,
  authDomain?: string,
  authVersion?: string,
  refreshToken?: string
) => {
  const {
    AUTH0_DOMAIN,
    AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE,
    AUTH0_DB_CONNECTION,
    AUTH0_RESPONSE_MODE,
    AUTH0_RESPONSE_TYPE,
    REDIRECT_URL
  } = useEnv();

  const webAuth: WebAuth = useMemo(
    () =>
      new WebAuth({
        domain: AUTH0_DOMAIN || "",
        clientID: AUTH0_CLIENT_ID || "",
        responseType: AUTH0_RESPONSE_TYPE,
        redirectUri: REDIRECT_URL,
        responseMode: AUTH0_RESPONSE_MODE,
        scope: AUTH0_SCOPES,
        audience: AUTH0_AUDIENCE
      }),
    []
  );

  const [authUser, setAuthUser] = useState<Auth0UserProfile>(
    {} as Auth0UserProfile
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const didIntialize = useRef(false);
  const [authError, setAuthError] = useState<string | undefined>();

  useEffect(() => {
    if (didIntialize.current) return;
    (async (): Promise<void> => {
      checkSession();
      didIntialize.current = true;
    })();
  }, [webAuth]);

  const getAuthUser = (
    accessToken: string,
    callback: (user: Auth0UserProfile) => void,
    unauthorizedCallback?: () => void
  ) => {
    webAuth.client.userInfo(accessToken, async (err, user) => {
      if (err) unauthorizedCallback && unauthorizedCallback();
      else callback(user);
    });
  };

  const checkSession = () => {
    webAuth.checkSession(
      {
        scope: AUTH0_SCOPES,
        audience: AUTH0_AUDIENCE
      },
      (err, authResult) => {
        if (
          err?.code === "login_required" ||
          authResult?.accessToken === undefined
        ) {
          //
          // password auth needs either a refresh token or
          // it will need to exhcange a refresh token for a new one
          if (!refreshToken) {
            setIsLoading(false);
          } else if (
            refreshToken &&
            // current auth domain is valid with current refresh token
            // and our auth implementation version
            (import.meta.env.VITE_AUTH0_DOMAIN !== authDomain ||
              import.meta.env.VITE_AUTH_VERSION !== authVersion)
          ) {
            setAuthError("session_expired");
            setIsLoading(false);
            onSessionInvalidate({
              resetOnboarding: false
            });
          } else if (refreshToken) {
            axios
              .post(
                `https://${import.meta.env.VITE_AUTH0_DOMAIN}/oauth/token`,
                {
                  grant_type: "refresh_token",
                  client_id: `${import.meta.env.VITE_AUTH0_CLIENT_ID}`,
                  refresh_token: refreshToken,
                  scope: AUTH0_SCOPES
                },
                { headers: { "Content-Type": "application/json" } }
              )
              .then((res) => {
                const result = res.data;

                getAuthUser(result.access_token || "", (user) => {
                  setAuthUser(user);
                  setIsLoading(false);
                  setAccessToken(String(result.access_token));
                  onSessionChange({
                    domain: AUTH0_DOMAIN || "",
                    version: import.meta.env.VITE_AUTH_VERSION,
                    refreshToken: result.refreshToken || result.refresh_token,
                    expiresAt: result.expiresIn
                      ? result.expiresIn * 1000 + new Date().getTime()
                      : result.expires_in * 1000 + new Date().getTime()
                  });
                });
              })
              .catch(() => {
                setAuthError("session_expired");
                setIsLoading(false);
                onSessionInvalidate({
                  resetOnboarding: false
                });
              });
          }
        } else if (authResult.accessToken) {
          //
          // social auth will always bypass login_required when
          // webAuth.checkSession is called
          //
          getAuthUser(authResult.accessToken, (user: Auth0UserProfile) => {
            setAuthUser(user);
            setAccessToken(authResult.accessToken);
            setIsLoading(false);
            onSessionChange({
              version: import.meta.env.VITE_AUTH_VERSION,
              domain: AUTH0_DOMAIN || ""
            });
          });
        }
      }
    );
  };

  const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) =>
    new Promise((resolve, reject) => {
      webAuth.signup(
        {
          connection: AUTH0_DB_CONNECTION || "",
          email,
          password,
          userMetadata: {
            firstName,
            lastName
          }
        },
        async (err, result) => {
          if (err) return reject(err);

          return resolve(result);
        }
      );
    });

  const login = async (email: string, password: string) =>
    new Promise((resolve, reject) => {
      webAuth.client.login(
        {
          realm: AUTH0_DB_CONNECTION || "",
          username: email,
          password
        },
        (err, result) => {
          if (err) return reject(err);

          getAuthUser(result.accessToken, (user: Auth0UserProfile) => {
            if (user) {
              setAuthUser(user);
              setIsLoading(false);
              setAccessToken(result.accessToken);
              onSessionChange({
                version: import.meta.env.VITE_AUTH_VERSION,
                domain: AUTH0_DOMAIN || "",
                refreshToken: result.refreshToken || result.refresh_token,
                expiresAt: result.expiresIn
                  ? result.expiresIn * 1000 + new Date().getTime()
                  : result.expires_in * 1000 + new Date().getTime()
              });
            }
          });

          return resolve(result);
        }
      );
    });

  const loginSSO = (connection: string, returnUrl?: string) => {
    webAuth.authorize({
      connection,
      audience: AUTH0_AUDIENCE,
      responseType: "code",
      responseMode: "query",
      redirectUri: returnUrl || window.location.origin + "/authCallback"
    });
  };

  const logout = (options?: LogoutOptions) => {
    const { returnTo } = options || {};

    setIsLoading(true);
    setAccessToken(undefined);
    onSessionInvalidate(options);

    const returnToUrl = returnTo
      ? window.location.origin + returnTo
      : REDIRECT_URL;

    console.log("JRY DEBUG --> useAuth0 --> logout --> returnToUrl", {
      returnToUrl,
      returnTo,
      REDIRECT_URL
    });

    webAuth.logout({
      returnTo: returnToUrl
    });
  };

  return {
    accessToken,
    isLoading,
    authUser,
    login,
    loginSSO,
    logout,
    signUp,
    authError
  };
};

export { useAuth0 };
