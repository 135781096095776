// import
import { useIntake } from "contexts";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { useFeatureFlag } from "contexts/FeatureFlagContext";
import {
  Enum_Subscriptionplan_Interval,
  Enum_Subscriptionplan_Plantype
} from "graphql/strapi-cms";
import { IntakePageSlug } from "pages/intake/IntakePage";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { resolveBackgroundLocation } from "utils/location";

const useOnboardingRedirects = (slug: IntakePageSlug) => {
  const { currentUser, currentUserIsTrialing } = useCurrentUser();
  const { healthHistory, selectedPlan } = useIntake();
  const { isFeatureEnabled } = useFeatureFlag();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const openOverlay = location.state?.openOverlay;
  const canRedirect =
    ![
      `/intake/${IntakePageSlug.AuthCallback}`,
      `/intake/${IntakePageSlug.CompleteOnboarding}`,
      `/subscribe/create-your-account`
    ].includes(pathname) && !openOverlay;
  const planType = (
    selectedPlan?.planType || Enum_Subscriptionplan_Plantype.Starter
  ).toLowerCase();
  const interval = (
    selectedPlan?.interval || Enum_Subscriptionplan_Interval.Monthly
  ).toLowerCase();

  useEffect(() => {
    // NOTE: JRY - Redirect to intake if the slug is not valid
    if (!Object.values(IntakePageSlug).includes(slug as IntakePageSlug)) {
      navigate("/intake");
    }
  }, [slug]);

  useEffect(() => {
    if (openOverlay) {
      navigate(openOverlay, {
        state: {
          backgroundLocation: {
            pathname: location.pathname,
            search: location.search
          }
        }
      });
    }
  }, [openOverlay]);

  useEffect(() => {
    if (!canRedirect) return;

    const hasAccount = !!currentUser;
    const hasHealthHistory = healthHistory.length > 0;
    const isOnHealthHistory = slug === IntakePageSlug.HealthHistory;
    const isOnFitness = slug === IntakePageSlug.FitnessProfile;

    // already on fitness, open the overlay -- since openOverlay
    // has not been requested
    if (
      hasAccount &&
      !currentUserIsTrialing &&
      isOnFitness &&
      isFeatureEnabled("web-app_onboarding-trial")
    ) {
      navigate(`/subscribe/${planType}/start-trial/${interval}`, {
        state: {
          backgroundLocation: resolveBackgroundLocation(location)
        }
      });
    }

    // has an account, goto fitness, open overla if the user
    // has not already started a trial
    if (hasAccount && !isOnFitness) {
      navigate(
        {
          pathname: `/intake/${IntakePageSlug.FitnessProfile}/1`,
          search: location.search
        },
        {
          state: {
            openOverlay:
              !currentUserIsTrialing &&
              isFeatureEnabled("web-app_onboarding-trial")
                ? `/subscribe/${planType}/start-trial/${interval}`
                : undefined
          }
        }
      );
    }

    // does not have health history, put them on health history.
    else if (!hasHealthHistory && !isOnHealthHistory) {
      navigate({
        pathname: `/intake/${IntakePageSlug.HealthHistory}/1`,
        search: location.search
      });
    }
  }, [currentUser]);
};

export { useOnboardingRedirects };
