import { useApolloClient } from "@apollo/client";
// Valesco
import {
  Button,
  Checkbox,
  notify,
  SocialButtonType,
  TextInput
} from "@getwellen/valesco";
import { Auth0Error, Auth0Result, DbSignUpResults } from "auth0-js";
// Components
import { ButtonLoading } from "components/button/ButtonLoading";
import { PasswordInput } from "components/form/password-input/PasswordInput";
import LoadingSpinner from "components/loading/LoadingSpinner";
import { SocialButtons } from "components/login-sign-up/SocialButtons";
import { useAuth } from "contexts/AuthContext";
// Contexts
import { useCurrentUser } from "contexts/CurrentUserContext";
import { useGeolocation } from "contexts/GeolocationContext";
import { AccountSetupInput } from "contexts/IntakeContext";
// GraphQL
import {
  GetCurrentUserDocument,
  ProfileItemAttributes,
  useCreateUserWithProfileMutation,
  useGetCurrentUserStripeSetupIntentLazyQuery,
  User,
  useUpdateUserProfileMutation
} from "graphql/rails-api";
import { SubscriptionPlan } from "graphql/strapi-cms";
// Pages
import { usePostLoginAnalytics } from "pages/auth/usePostLogin";
import { useSocialLogin } from "pages/auth/useSocialLogin";
import useMembershipReferral from "pages/intake/useMembershipReferral";
import React, { memo, useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  errorHelpText,
  validEmailPattern,
  validPasswordRegex
} from "utils/forms";

import { orderRoute, OsteoboostOrderSlug } from "./OsteoboostOrderPage";

type OrderCreateAccountOverlayProps = {
  // Defines where to go after successfully logging in
  returnTo?: string;
  loginReirect?: string;
  healthHistory?: ProfileItemAttributes[];
  selectedPlan?: SubscriptionPlan;
  title?: string;
  subtitle?: string;
};

const loginReirectDefault = `/login`;

/**
 * Allow caller to override the intake object instead of the locaton.state.intake
 */
const OrderCreateAccountOverlay: React.FC<OrderCreateAccountOverlayProps> = ({
  loginReirect = loginReirectDefault,
  ...props
}) => {
  const intakeOverride = {
    healthHistory: props.healthHistory,
    selectedPlan: props.selectedPlan
  };
  // state may not exist if we are rendering this component outside of the intake flow (via the orders flow)
  const location = useLocation();
  const { healthHistory, selectedPlan } =
    location.state?.inake || intakeOverride;

  const { isAuthenticated } = useAuth();

  // HOOK - useOrderCreateUser
  const { isLoading: createUserLoading, loginAndCreateUser } =
    useOrderCreateUser(healthHistory, selectedPlan);

  // HOOK - useOrderRegisterUser
  const {
    isLoading: registerLoading,
    control,
    errors,
    onBack,
    passwordPolicyError,
    onSubmit,
    onSSOSubmit
  } = useOrderRegisterUser(
    // onSignupSuccess
    useCallback((authSignUp: AccountSetupInput) => {
      loginAndCreateUser(authSignUp);
    }, [])
  );

  const isLoading = createUserLoading || registerLoading;

  const accountSetup = location.state?.accountSetup;

  const socialButtons = [SocialButtonType.Google, SocialButtonType.Facebook];

  if (isAuthenticated) return <LoadingSpinner className="text-geebung-500" />;

  return (
    <div className="w-full">
      <h1 className="mb-3 text-left font-display text-3xl">
        {accountSetup?.title ||
          props.title ||
          "Your personalized workout program is almost ready!"}
      </h1>
      <p className="mb-8">
        {accountSetup?.subtitle ||
          props.subtitle ||
          "But first, let's set up your account."}
      </p>
      {socialButtons.length > 0 && (
        <>
          <SocialButtons
            buttons={socialButtons}
            ctaText="Continue with"
            onClick={onSSOSubmit}
          />
          <div className="relative mb-6 flex items-center">
            <div className="grow border-t border-gray-400"></div>
            <span className="mx-3 shrink text-xs text-cello-400">OR</span>
            <div className="grow border-t border-gray-400"></div>
          </div>
        </>
      )}
      <form className="mb-6" onSubmit={onSubmit}>
        <div className="jsutify-between mb-6 flex">
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                className="mr-4"
                error={!!errors.firstName}
                helpText={errorHelpText(errors.firstName)}
                label="First name"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{ required: true }}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                error={!!errors.lastName}
                helpText={errorHelpText(errors.lastName)}
                label="Last name"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{ required: true }}
          />
        </div>
        <div className="mb-6">
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                error={!!errors.email}
                helpText={errorHelpText(errors.email)}
                label="Email"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{ required: true, pattern: validEmailPattern }}
          />
        </div>
        <div className="mb-8">
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, onBlur, value } }) => (
              <PasswordInput
                autoComplete="new-password"
                error={errors.password}
                label="Password"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: true,
              minLength: {
                value: 8,
                message: "Password must be at least 8 characters long"
              },
              validate: (value) =>
                !!new RegExp(validPasswordRegex).test(value) ||
                "Password must contain lower case letters (a-z), upper case letters (A-Z), and numbers (i.e 0-9)"
            }}
          />
        </div>
        <div className="mb-8 flex">
          <Controller
            control={control}
            name="agreeTerms"
            render={({ field: { onChange, onBlur, value } }) => (
              <Checkbox
                checked={value}
                error={!!errors.agreeTerms}
                id="agreeTermsID"
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
            rules={{
              required: true
            }}
          />
          <label className="text-base text-cello-500" htmlFor="agreeTermsID">
            By creating an account, I agree to the{" "}
            <Link
              className="font-semibold underline"
              target="blank"
              to="https://www.osteoboost.com/terms-of-use/"
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              className="font-semibold underline"
              target="blank"
              to="https://www.osteoboost.com/privacy-policy/"
            >
              Privacy Policy.
            </Link>{" "}
            Already have an account?{" "}
            <Link className="font-semibold underline" to={loginReirect}>
              Login here.
            </Link>
          </label>
        </div>
        <div className="flex justify-between">
          <Button action="secondary" onClick={onBack} variant="subtle">
            Cancel
          </Button>
          <Button action="secondary" disabled={isLoading} type="submit">
            <ButtonLoading isLoading={isLoading}>Submit</ButtonLoading>
          </Button>
        </div>
        {passwordPolicyError && (
          <div className="mt-2 text-xs text-red">
            <ReactMarkdown
              components={{
                ul: ({ children }) => <ul className="ml-4">{children}</ul>,
                li: ({ children }) => <li className="list-disc">{children}</li>
              }}
            >
              {passwordPolicyError}
            </ReactMarkdown>
          </div>
        )}
      </form>
    </div>
  );
};

// Custom React hook `useOrderCreateUser` manages the creation and updating of a user account and profile.
// It utilizes several hooks and mutations to authenticate, create, and update a user profile within an application.
// Key steps are:
// 1. Retrieve user authentication state and location via `useLocation()` and `useAuth()` hooks.
// 2. Access signup data from either the location state or session storage, preparing it for further use.
// 3. Define `loginAndCreateUser` function:
//    - Handles both the user login process and subsequent user creation, depending on authentication status.
//    - If already authenticated, it proceeds to create the user in the database.
//    - If not authenticated, it logs in the user using the provided signup data, then creates the user.
// 4. Define `createUser` function:
//    - Sends a mutation to create a user with profile information, including health history, referral data, and geolocation.
//    - Upon success, updates the Apollo client cache with the created user and triggers analytics tracking.
// 5. Define `updateUser` function:
//    - Sends a mutation to update the user's health history.
// 6. Return an object that exposes `loginAndCreateUser`, `createUser`, and `updateUser` along with loading states.
const useOrderCreateUser = (
  healthHistory: ProfileItemAttributes[],
  selectedPlan?: SubscriptionPlan,
  returnTo?: string
) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const authSignUp = state?.authSignUp || undefined;
  const { membershipReferral } = useMembershipReferral();
  const geolocation = useGeolocation();

  const { getAccessTokenSilently, authUser, login, isAuthenticated } =
    useAuth();
  const [createUserWithProfile, { loading: isCreateUserLoading }] =
    useCreateUserWithProfileMutation();
  const [updateProfile, { loading: isUpdateProfileLoading }] =
    useUpdateUserProfileMutation();
  const { postLoginAnalytics, postSignupAnalytics } = usePostLoginAnalytics();
  const [getSetupIntent] = useGetCurrentUserStripeSetupIntentLazyQuery();
  const client = useApolloClient();

  const cachedSignUp: AccountSetupInput = (
    !!authSignUp
      ? authSignUp
      : JSON.parse(sessionStorage.getItem("auth.signup") || "{}")
  ) as AccountSetupInput;

  const loginAndCreateUser = useCallback(
    (signUpData?: AccountSetupInput) => {
      const signUp = signUpData || cachedSignUp;

      console.log(
        "JRY DEBUG --> useOrderCreateUser -> loginAndCreateUser -> signup, isAuthenticated",
        { signUp, isAuthenticated }
      );

      // NOTE: @jry - We will be authenticated after Auth0 successfully
      // registers a new token, but before we've created the user in the Wellen DB

      if (isAuthenticated) {
        console.log(
          "JRY DEBUG --> useOrderCreateUser -> loginAndCreateUser -> \
          CALLING createUser(signUp authUser)",
          { signUp, authUser }
        );
        createUser(authUser, signUp).then(() => {
          if (returnTo) {
            // When createUser is complete, navigate ourselves to the next route
            // rather than letting useOsteoboostOrderRoute from doing it
            navigate(returnTo, { replace: true });
          }
        });
      } else {
        console.log(
          "JRY DEBUG --> useOrderCreateUser -> loginAndCreateUser -> CALLING login(signUp.email, signup,password)",
          { signUp }
        );
        login(signUp.email, signUp.password)
          .then((result) => {
            console.log(
              "JRY DEBUG --> useOrderCreateUser -> loginAndCreateUser -> FINISHED login -> CALLING createUser (result, signUP)",
              { result, signUp }
            );
            createUser(result, signUp).then(() => {
              if (returnTo) {
                // When createUser is complete, navigate ourselves to the next route
                // rather than letting useOsteoboostOrderRoute from doing it
                navigate(returnTo, { replace: true });
              }
            });
          })
          .catch(() => {
            notify.error("Authentication error");
          });
      }
    },
    [isAuthenticated, healthHistory]
  );

  const createUser = useCallback(
    async (user: unknown, accountSetup: AccountSetupInput): Promise<User> => {
      const authResult = user as Auth0Result;
      const token = authResult.accessToken || getAccessTokenSilently();
      try {
        const result = await createUserWithProfile({
          context: {
            clientName: "rails-api",
            headers: {
              Authorization: "Bearer " + token
            }
          },
          variables: {
            userAttributes: {
              isOsteoboost: true,
              firstName: accountSetup.firstName,
              lastName: accountSetup.lastName,
              authProvider: {
                uid: accountSetup.uid,
                provider: accountSetup.provider
              },
              email: accountSetup.email,
              hasAcceptedDisclaimer: true
            },
            profileAttributes: {},
            locationAttributes: {
              ipAddress: geolocation?.ip || "",
              country: geolocation?.country || "",
              countryCode: geolocation?.country_code || "",
              region: geolocation?.region || "",
              city: geolocation?.city || "",
              postal: geolocation?.postal || "",
              latitude: geolocation?.latitude || 0,
              longitude: geolocation?.longitude || 0,
              timezone: geolocation?.timezone || ""
            }
          }
        });

        const user = result.data?.createUserWithProfile as User;

        if (!user) {
          throw new Error("User creation failed");
        }

        client.cache.writeQuery({
          query: GetCurrentUserDocument,
          data: user
        });

        window.sessionStorage.removeItem("auth.signup");

        postSignupAnalytics();
        postLoginAnalytics(user);

        // Immediately after creating a user, fetch their setup intents as a
        // means to create a setup intent if one does not exist.
        //
        // This prevents redirecting to the payments page before a setup intent
        // is created (race) which will cause an error
        await getSetupIntent({
          context: { clientName: "rails-api" },
          fetchPolicy: "no-cache"
        });

        return user;
      } catch (err) {
        console.error(err);
        notify.error("Authentication error");
        throw err; // Ensure the promise is rejected if an error occurs
      }
    },
    [healthHistory, selectedPlan]
  );

  const updateUser = useCallback(
    () =>
      updateProfile({
        context: { clientName: "rails-api" },
        variables: {
          attributes: {
            healthHistory
          }
        }
      }),
    [healthHistory]
  );

  return {
    loginAndCreateUser,
    createUser,
    updateUser,
    isLoading: isCreateUserLoading || isUpdateProfileLoading
  };
};

interface Auth0SignUpResult extends DbSignUpResults {
  Id: string;
}

const useOrderRegisterUser = (
  onSignUpSuccess: (result: AccountSetupInput) => void
) => {
  const location = useLocation();

  const accountSetup = location.state?.intake?.accountSetup;

  const { currentUserIsLoading } = useCurrentUser();
  const { signUp, isLoading: authIsLoading } = useAuth();
  const navigate = useNavigate();
  const [passwordPolicyError, setPasswordPolicyError] = useState<string | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { errors, isValid },
    getValues,
    trigger,
    setError
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: (accountSetup?.email as string) || "",
      password: "",
      firstName: "",
      lastName: "",
      agreeTerms: false
    }
  });

  const onSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      trigger();
      if (!isValid) return;

      setIsLoading(true);
      signUp(
        getValues().email,
        getValues().password,
        getValues().firstName,
        getValues().lastName
      )
        .then(handleSignUpSuccess)
        .catch(handleSignUpError);
    },
    [isValid, trigger]
  );

  const { onSocialLoginClick } = useSocialLogin();

  const onSSOSubmit = useCallback(
    (buttonType: SocialButtonType) => {
      onSocialLoginClick(
        buttonType,
        window.location.origin + orderRoute(OsteoboostOrderSlug.AuthCallback)
      );
    },
    [onSocialLoginClick]
  );

  const onBack = useCallback(() => navigate(-1), []);

  const handleSignUpSuccess = useCallback((result: unknown) => {
    const authResult = result as Auth0SignUpResult;
    const authSignUp: AccountSetupInput = {
      email: authResult.email,
      firstName: getValues().firstName,
      lastName: getValues().lastName,
      password: getValues().password,
      provider: "auth0",
      uid: authResult.Id
    };

    window.sessionStorage.setItem("auth.signup", JSON.stringify(authSignUp));

    onSignUpSuccess(authSignUp);
  }, []);

  const handleSignUpError = useCallback((err: Auth0Error) => {
    if (err?.name === "PasswordStrengthError") {
      setPasswordPolicyError(err?.policy || null);
      setError("password", {
        type: "custom",
        message: "Your password is too weak, it must contain:"
      });
    } else {
      notify.error(
        "Something went wrong. If you already have an account, please log in instead."
      );
    }

    setIsLoading(false);
  }, []);

  return {
    control,
    errors,
    isValid,
    isLoading: isLoading || authIsLoading || currentUserIsLoading,
    setError,
    onSubmit,
    onSSOSubmit,
    onBack,
    passwordPolicyError
  };
};

export default memo(OrderCreateAccountOverlay);
