import { Spinner } from "@getwellen/valesco";
import { useOsteoboostOrder } from "contexts/OsteoboostOrderContext";
import { PaymentProvider } from "contexts/PaymentContext";
import { useScrollToTop } from "hooks/useScrollToTop";
import LoginPage from "pages/auth/LoginPage";
import { memo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { BillingAddressForm } from "./BillingAddressForm";
import { FormStep } from "./FormStep";
import { OrderComplete } from "./OrderComplete";
import OrderCreateAccount from "./OrderCreateAccount";
import { OrderPaymentPage } from "./OrderPaymentPage";
import { PatientForm } from "./PatientForm";
import { PrescriberForm } from "./PrescriberForm";
import { PrescriptionForm } from "./PrescriptionForm";
import { ShippingAddressForm } from "./ShippingAddresForm";
import { SizingForm } from "./SizingForm";
import { useOrder } from "./useOrder";

export const OsteoboostBaseRoute = "/order";

export enum OsteoboostOrderSlug {
  Root = "",
  Login = "login",
  Signup = "signup",
  AuthCallback = "authCallback",
  Payment = "payment",
  Patient = "patient",
  Sizing = "sizing",
  Shipping = "shipping",
  Billing = "billing",
  Prescription = "prescription",
  Prescriber = "prescriber",
  Complete = "complete"
}

const formOrder = [
  OsteoboostOrderSlug.Patient,
  OsteoboostOrderSlug.Sizing,
  OsteoboostOrderSlug.Shipping,
  OsteoboostOrderSlug.Billing,
  OsteoboostOrderSlug.Prescription,
  OsteoboostOrderSlug.Prescriber
];
const formSteps = formOrder.length;
export const orderRoute = (slug: string) =>
  slug.length ? `/order/${slug}` : `/order`;

const OsteoboostOrderPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { isLoading, order } = useOsteoboostOrder();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  useScrollToTop();

  if (isLoading) {
    return (
      <div className="mt-[75%] flex items-center justify-center md:mt-[33%]">
        <Spinner className="fill-geebung-500" />
      </div>
    );
  }

  let CurrentPage = null;
  switch (slug) {
    // LOGIN FORM
    case OsteoboostOrderSlug.Login: {
      CurrentPage = (
        <LoginPage
          isOsteoboost={true}
          signupRedirect={orderRoute(OsteoboostOrderSlug.Signup)}
        />
      );
      break;
    }
    // SIGNUP FORM
    case OsteoboostOrderSlug.Signup: {
      CurrentPage = (
        <div className="h-full p-4 pt-16 md:m-auto md:max-w-md md:pt-32">
          <OrderCreateAccount
            loginReirect={orderRoute(OsteoboostOrderSlug.Login)}
            subtitle="Safely store your account details and preorder your Osteoboost today."
            title="Create your Osteoboost Account"
          />
        </div>
      );
      break;
    }
    // PAYMENT FORM
    case OsteoboostOrderSlug.Payment: {
      CurrentPage = (
        <PaymentProvider>
          <div className="h-full p-4 pt-16 md:m-auto mx-auto w-auto md:my-6 md:max-w-4xl md:pt-32">
            <OrderPaymentPage
              description="Complete payment to reserve your device. We’ll gather your patient and prescriber information next, and shipping is expected to begin in December."
              navigateTo={orderRoute(OsteoboostOrderSlug.Patient)}
              title="Pay now to secure your Osteoboost"
            />
          </div>
        </PaymentProvider>
      );
      break;
    }
    // MULTISTEP FORM
    case OsteoboostOrderSlug.Patient: {
      CurrentPage = (
        <FormStep
          description="Please provide your details to complete your preorder."
          onNext={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Sizing), {
              state: { from: orderRoute(OsteoboostOrderSlug.Patient) }
            });
          }}
          showBack={false}
          step={1}
          title="Enter your patient information"
          total={formSteps}
        >
          {(props) => <PatientForm {...props} />}
        </FormStep>
      );
      break;
    }
    case OsteoboostOrderSlug.Sizing: {
      const description = `Measure the circumference of your hips to select the right
      size. Osteoboost is designed to fit comfortably over a thin layer of clothing to
      ensure effective vibration therapy.`;

      CurrentPage = (
        <FormStep
          description={description}
          onBack={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Patient), {
              state: { from: orderRoute(OsteoboostOrderSlug.Sizing) }
            });
          }}
          onNext={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Shipping), {
              state: { from: orderRoute(OsteoboostOrderSlug.Sizing) }
            });
          }}
          step={2}
          title="Select your Osteoboost size"
          total={formSteps}
        >
          {(props) => <SizingForm description="" {...props} />}
        </FormStep>
      );
      break;
    }
    case OsteoboostOrderSlug.Shipping: {
      CurrentPage = (
        <FormStep
          description="Provide your shipping address to ensure smooth delivery. Please be aware that Osteoboost may not ship until December or later."
          onBack={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Sizing), {
              state: { from: orderRoute(OsteoboostOrderSlug.Shipping) }
            });
          }}
          onNext={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Billing), {
              state: { from: orderRoute(OsteoboostOrderSlug.Shipping) }
            });
          }}
          step={3}
          title="Enter your shipping address"
          total={formSteps}
        >
          {(props) => <ShippingAddressForm {...props} />}
        </FormStep>
      );
      break;
    }
    case OsteoboostOrderSlug.Billing: {
      CurrentPage = (
        <FormStep
          description="Verify your billing address to complete your preorder."
          onBack={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Shipping), {
              state: { from: orderRoute(OsteoboostOrderSlug.Billing) }
            });
          }}
          onNext={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Prescription), {
              state: { from: orderRoute(OsteoboostOrderSlug.Billing) }
            });
          }}
          step={4}
          subtitle=""
          title="Enter your billing address"
          total={formSteps}
        >
          {(props) => <BillingAddressForm {...props} />}
        </FormStep>
      );
      break;
    }
    case OsteoboostOrderSlug.Prescription: {
      CurrentPage = (
        <FormStep
          description="Choose how you’d like to provide your prescription to complete your Osteoboost order."
          onBack={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Billing), {
              state: { from: orderRoute(OsteoboostOrderSlug.Prescription) }
            });
          }}
          onNext={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Prescriber), {
              state: { from: orderRoute(OsteoboostOrderSlug.Prescription) }
            });
          }}
          step={5}
          subtitle=""
          title="Submit your prescription"
          total={formSteps}
        >
          {(props) => <PrescriptionForm {...props} />}
        </FormStep>
      );
      break;
    }
    case OsteoboostOrderSlug.Prescriber: {
      CurrentPage = (
        <FormStep
          description="Provide your prescriber’s details to complete the order process. This information helps us verify your prescription once received."
          onBack={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Prescription), {
              state: { from: orderRoute(OsteoboostOrderSlug.Prescriber) }
            });
          }}
          onNext={() => {
            navigate(orderRoute(OsteoboostOrderSlug.Complete), {
              state: { from: orderRoute(OsteoboostOrderSlug.Prescriber) }
            });
          }}
          step={6}
          subtitle=""
          title="Enter prescriber information"
          total={formSteps}
        >
          {(props) => <PrescriberForm {...props} />}
        </FormStep>
      );
      break;
    }
    case OsteoboostOrderSlug.Complete: {
      CurrentPage = <OrderComplete />;
      break;
    }
    default: {
      console.error(`Unknown route for /orders = ${slug}`);
      navigate(orderRoute(OsteoboostOrderSlug.Root));
      break;
    }
  }

  return CurrentPage;
};

export default memo(OsteoboostOrderPage);
