import { Button } from "@getwellen/valesco";
import {
  OrderDetails,
  OrderNumber,
  OrderTitle
} from "components/order-complete";
import { OrderNextSteps } from "components/order-complete/OrderNextSteps";
import {
  sizeRename,
  useOsteoboostOrder
} from "contexts/OsteoboostOrderContext";
import { Product } from "graphql/rails-api";
import LoadingPage from "pages/LoadingPage";
import { useEffect } from "react";

export const OrderComplete = () => {
  const { isLoading, order, products, refetchOrder } = useOsteoboostOrder();
  const orderNumber = order.orderNumber;
  const orderDate = new Date(order.orderDate || "").toLocaleDateString(
    "en-US",
    {
      year: "numeric",
      month: "short",
      day: "numeric"
    }
  );
  const orderProduct = products.find(
    (x) => x.id === order.sizing.id
  ) as Product;
  const shippingAddress = order.shippingAddress;
  const billingAddress = order.billingAddress;

  // Refetch the order only once on load
  useEffect(() => {
    refetchOrder();
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  const orderDetails = [
    <div key="items-summary">
      <span className="text-base font-heading text-cello-500 font-semibold">
        Osteoboost Founders Program
      </span>
      <ul className="list-disc list-inside">
        <li>{`Osteoboost, Size ${sizeRename[orderProduct.size]} | ${orderProduct.name}`}</li>
        <li>1-year free of Wellen by Osteoboost</li>
        <li>2-year limited warranty</li>
      </ul>
    </div>,
    <div key="shipping-address">
      <span className="block text-base font-heading text-cello-300 font-semibold mb-4">
        Shipping address
      </span>
      <div>
        <span className="block">{`${shippingAddress.firstName} ${shippingAddress.lastName}`}</span>
        <span className="block">{`${shippingAddress.address1}${shippingAddress.address2 ? `, ${shippingAddress.address2}` : ""}`}</span>
        <span className="block">{`${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zipcode}`}</span>
      </div>
    </div>,
    <div key="billing-address">
      <span className="block text-base font-heading text-cello-300 font-semibold mb-4">
        Billing address
      </span>
      <div>
        <span className="block">{`${billingAddress.firstName} ${billingAddress.lastName}`}</span>
        <span className="block">{`${billingAddress.address1}${billingAddress.address2 ? `, ${billingAddress.address2}` : ""}`}</span>
        <span className="block">{`${billingAddress.city}, ${billingAddress.state} ${billingAddress.zipcode}`}</span>
      </div>
    </div>,
    <div key="payment-method">
      <span className="block text-base font-heading text-cello-300 font-semibold mb-4">
        Payment method
      </span>
      <div>
        <span className="block">Visa ending in 0100</span>
        <span className="block font-bold">{`$${orderProduct.price}`}</span>
      </div>
    </div>
  ];

  const nextSteps = [
    {
      title: "Submit your prescription",
      children: (
        <>
          <span className="block">
            Please ask your doctor to call, fax, or e-prescribe your
            prescription to HealthWarehouse, our pharmacy partner based in
            Kentucky, USA.
          </span>
          <br />
          <span className="block">
            Phone:{" "}
            <a
              className="text-cello-500 font-semibold underline"
              href="tel:800-748-7001"
            >
              800-748-7001
            </a>
            <br />
            Fax:{" "}
            <a
              className="text-cello-500 font-semibold underline"
              href="tel:888-870-2808"
            >
              888-870-2808
            </a>
          </span>
        </>
      )
    },
    {
      title: "Shipping information",
      children: (
        <>
          We will ship your Osteoboost as soon as it is in stock. While we aim
          for a December ship date, please note that we cannot guarantee a
          specific date.
        </>
      )
    },
    {
      title: "Enjoy your exclusive benefits",
      children: (
        <div className="grid grid-cols-1 gap-8">
          <span className="block">
            As a Founders Program member, you’re guaranteed a first spot in line
            for Osteoboost. Plus, you receive a 1-year free membership to Wellen
            by Osteoboost. Use coupon code{" "}
            <strong className="text-cello-700">FOUNDER2024</strong> at checkout
            on{" "}
            <a
              className="font-semibold underline text-cello-500"
              href="https://getwellen.com"
            >
              getwellen.com
            </a>{" "}
            to claim your membership today.{" "}
          </span>
          <Button
            action="primary"
            className="w-full"
            href="https://getwellen.com"
            target="_blank"
            variant="bold"
          >
            Claim my 1-year free membership
          </Button>
        </div>
      )
    }
  ];

  return (
    <div className="min-h-screen w-full px-4 pb-24 pt-16 md:m-auto md:mt-10 md:pb-72 md:pt-12 md:w-[750px]">
      <div className="grid grid-col-1 gap-[4rem]">
        <div className="grid grid-col-1 gap-8">
          <OrderTitle />
          <OrderNumber
            orderDate={orderDate || ""}
            orderNumber={String(orderNumber || 0)}
          />
          <div className="h-[1px] bg-lynch-200"></div>
          <OrderDetails>{orderDetails}</OrderDetails>
          <OrderNextSteps steps={nextSteps} />
        </div>
        <div className="grid grid-col-1 gap-8">
          <h1 className="font-display text-6xl">Other important information</h1>
          <div className="grid grid-col-1 gap-4">
            <h3 className="font-display text-2xl">
              Refund and warranty information
            </h3>
            <p>
              {" "}
              You may cancel your order at any time before shipment for a full
              refund. Once shipped, you can return your device within 30 days
              for a full refund, provided it is still in its original packaging
              and unused. Your device also comes with an extended 2-year limited
              warranty. {" "}
            </p>
          </div>
          <div className="grid grid-col-1 gap-4">
            <h3 className="font-display text-2xl">Need assistance?</h3>
            <p>
              If you need to cancel your order or update your personal
              information, please contact us at{` `}
              <a
                className="font-semibold"
                href={`mailto:support@osteoboost.com`}
                rel="noreferrer"
                target="_blank"
              >
                support@osteoboost.com.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
