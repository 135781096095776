import { useAuth } from "contexts/AuthContext";
import {
  orderRoute,
  OsteoboostBaseRoute,
  OsteoboostOrderSlug
} from "pages/order/OsteoboostOrderPage";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LocationState } from "utils/location";

const usePrivateRoute = () => {
  const { isAuthenticated, authError, isLoading } = useAuth();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const state = useMemo(
    () =>
      ({
        returnTo:
          (searchParams.get("returnTo") as string) || window.location.pathname,
        openOverlay: searchParams.get("openOverlay") as string
      }) as LocationState,
    []
  );

  useEffect(() => {
    console.log(
      "JRY DEBUG --> usePrivateRoute --> isAuthenticated, isLoading, location.pathname",
      { location, isAuthenticated, isLoading }
    );
    if (!isAuthenticated && !isLoading) {
      if (location.pathname.includes(OsteoboostBaseRoute)) {
        navigate(
          orderRoute(OsteoboostOrderSlug.Signup) +
            (authError ? "?authError=" + authError : ""),
          {
            state
          }
        );
      } else {
        console.log("JRY DEBUG --> usePrivateRoute --> BACK TO LOGIN", {
          location,
          isAuthenticated,
          isLoading
        });
        navigate("/login" + (authError ? "?authError=" + authError : ""), {
          state
        });
      }
    }
  }, [isLoading, isAuthenticated]);

  return {
    isReady: isAuthenticated
  };
};

export { usePrivateRoute };
