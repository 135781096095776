import React from "react";
import { Outlet } from "react-router-dom";

import { useOsteoboostOrderRoute } from "./useOsteoboostOrderRoute";

const OstoeboostOrderRoute: React.FC = () => {
  console.log(
    `JRY DBEUG --> OsteoboostOrderRoute --> calling useOsteoboostOrderRoute`
  );
  useOsteoboostOrderRoute();
  // For now we are always rendering the pre-order page
  return <Outlet />;
};
export default OstoeboostOrderRoute;
