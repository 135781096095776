import { ApolloQueryResult } from "@apollo/client";
import * as Sentry from "@sentry/react";
import {
  Exact,
  GetCurrentUserQuery,
  ProfileItem,
  useGetCurrentUserQuery,
  User
} from "graphql/rails-api";
import LogRocket from "logrocket";
import React, { createContext, useContext } from "react";

import { useAuth } from "./AuthContext";

interface CurrentUserContext {
  currentUser: User | null;
  currentUserHasAcceptedDisclaimer: boolean;
  currentUserHasSeenWelcomeMessage: boolean;
  currentUserHasSubscription: boolean;
  currentUserIsDraft: boolean;
  currentUserIsLoading: boolean;
  currentUserIsOnboarded: boolean;
  currentUserIsPaymentFailure: boolean;
  currentUserIsSubscribed: boolean;
  currentUserIsTrialExpired: boolean;
  currentUserIsTrialing: boolean;
  currentUserIsUnsubscribed: boolean;
  currentUserCanAccessProgram: boolean;
  currentUserHasExternalMemberships: boolean;
  refetchCurrentUser: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetCurrentUserQuery>> | null;
  equipment: ProfileItem[];
  loadingComponent?: React.ReactNode;
}

const defaultState: CurrentUserContext = {
  currentUser: null,
  currentUserHasAcceptedDisclaimer: false,
  currentUserHasSeenWelcomeMessage: false,
  currentUserHasSubscription: false,
  currentUserIsDraft: false,
  currentUserIsLoading: true,
  currentUserIsOnboarded: false,
  currentUserIsPaymentFailure: false,
  currentUserIsSubscribed: false,
  currentUserIsTrialExpired: false,
  currentUserIsTrialing: false,
  currentUserIsUnsubscribed: false,
  currentUserCanAccessProgram: false,
  currentUserHasExternalMemberships: false,
  refetchCurrentUser: () => null,
  equipment: [],
  loadingComponent: undefined
};

const CurrentUserContext = createContext<CurrentUserContext>(defaultState);

const CurrentUserProvider: React.FC<{
  children: React.ReactNode;
  loadingComponent?: React.ReactNode;
}> = ({ children, loadingComponent }) => {
  const { isLoading: authIsLoading, isAuthenticated } = useAuth();

  const {
    data: currentUserData,
    loading: currentUserIsLoading,
    refetch
  } = useGetCurrentUserQuery({
    context: { clientName: "rails-api" },
    skip: authIsLoading || !isAuthenticated,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first"
  });

  const currentUser = (currentUserData?.getCurrentUser as User) || null;

  if (currentUser) {
    try {
      window.dataLayer.push({
        userId: currentUser.id,
        emailHash: currentUser.emailHash
      });

      if (import.meta.env.VITE_SENTRY_ENV !== "development") {
        LogRocket.init("cr4qiq/wellen", { shouldCaptureIP: false });
        LogRocket.identify(currentUser.id);
      }

      Sentry.setUser({
        id: currentUser.id
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        currentUserIsLoading,
        currentUserHasAcceptedDisclaimer:
          !!currentUser && currentUser.hasAcceptedDisclaimer,
        currentUserHasSeenWelcomeMessage:
          !!currentUser && currentUser.hasSeenWelcomeMessage,
        currentUserIsOnboarded: !!currentUser && currentUser.isOnboarded,
        currentUserIsSubscribed: !!currentUser && currentUser.isSubscribed,
        currentUserIsTrialing: !!currentUser && currentUser.isTrialing,
        currentUserIsTrialExpired: !!currentUser && currentUser.isTrialExpired,
        currentUserIsUnsubscribed: !!currentUser && currentUser.isUnsubscribed,
        currentUserIsPaymentFailure:
          !!currentUser && currentUser.isPaymentFailure,
        currentUserHasSubscription:
          !!currentUser && currentUser.hasSubscription,
        currentUserIsDraft: !!currentUser && currentUser.isDraft,
        currentUserCanAccessProgram:
          !!currentUser &&
          (currentUser.isTrialing || currentUser.isSubscribed) &&
          !currentUser.isPaymentFailure,
        currentUserHasExternalMemberships:
          !!currentUser &&
          (currentUser.externalMemberships || []).some(
            (m) => m.status == "active"
          ),
        refetchCurrentUser: refetch,
        equipment: (currentUser?.userProfile?.fitness?.items || []).filter(
          (i) => i.key === "equipment"
        ),
        loadingComponent
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);

  if (!context)
    throw new Error("useCurrentUser must be used within CurrentUserProvider");

  return context;
};

export { CurrentUserContext, CurrentUserProvider, useCurrentUser };
