import { Spinner } from "@getwellen/valesco";
import { useIntake } from "contexts";
import {
  ComponentContentFaq,
  ComponentContentPlanSelector,
  ComponentContentTestimonial
} from "graphql/strapi-cms";
import { useAuthError } from "hooks/useAuthError";
import { useOnboardingRedirects } from "hooks/useOnboardingRedirects";
import { useScrollToTop } from "hooks/useScrollToTop";
import IntakePlanPage from "pages/intake/plan/IntakePlanPage";
import IntakePageQuiz from "pages/intake/quiz/IntakePageQuiz";
import React, { memo, useRef } from "react";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import IntakePageAuthCallback from "./IntakePageAuthCallback";
import { IntakePageRecommend } from "./recommend/IntakePageRecommend";
import { useIntakePage } from "./useIntakePage";

export enum IntakePageSlug {
  FitnessProfile = "fitness-profile",
  AccountSetup = "account-setup",
  HealthHistory = "health-history",
  SelectPlan = "choose-your-plan",
  Recommend = "recommend",
  CompleteOnboarding = "completeOnboarding",
  AuthCallback = "authCallback"
}

const IntakePage: React.FC = () => {
  const { slug = IntakePageSlug.FitnessProfile, stepNum = "1" } = useParams();
  const {
    healthHistory,
    healthHistoryQuiz,
    fitnessProfileQuiz,
    intakePage,
    isLoading,
    isPricingLoading,
    subscriptionPlans
  } = useIntake();
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useScrollToTop();
  useAuthError();

  // NOTE: JRY -
  // This is going to check if we should redirect the user to health history if they don't have one.
  // If the user has an account but is not on the fitness overlay, then redirect them to the fitness profile and simulataneously open the trial overlay
  // If the user has an account and made it to the Fitness slug, then it will attempt to show the trial overlay
  useOnboardingRedirects(slug as IntakePageSlug);

  const {
    isPageLoading,
    onFirstStepCompleted,
    onHealthHistoryCompleted,
    onFitnessProfileCompleted,
    onPlanSelected
  } = useIntakePage(intakePage);

  return (
    <div
      ref={scrollRef}
      className="flex min-h-screen justify-center bg-cararra-500"
    >
      {/* <IntakeSidebar currentSection={slug || IntakePageSlug.FitnessProfile} /> */}
      <div
        className={twMerge(
          "w-full px-4 pb-24 pt-16 md:m-auto md:mt-10 md:pb-72 md:pt-12",
          slug === IntakePageSlug.SelectPlan ? "md:max-w-5xl" : "md:w-[750px]"
        )}
      >
        {isPageLoading && (
          <div className="mt-[75%] flex items-center justify-center md:mt-[33%]">
            <Spinner className="fill-geebung-500" />
          </div>
        )}
        {!isPageLoading && slug === IntakePageSlug.AuthCallback && (
          <IntakePageAuthCallback />
        )}
        {!isPageLoading && slug === IntakePageSlug.HealthHistory && (
          <IntakePageQuiz
            isLoading={isLoading}
            onComplete={onHealthHistoryCompleted}
            onNext={(stepNum) => {
              if (stepNum === 1 && healthHistory.length <= 1)
                onFirstStepCompleted();
            }}
            quiz={healthHistoryQuiz || {}}
            slug={slug}
            stepNum={Number(stepNum)}
          />
        )}
        {!isPageLoading && slug === IntakePageSlug.Recommend && (
          <IntakePageRecommend
            {...((intakePage?.planSelector as ComponentContentPlanSelector) ||
              {})}
          />
        )}
        {!isPageLoading && slug === IntakePageSlug.SelectPlan && (
          <IntakePlanPage
            {...((intakePage?.planSelector as ComponentContentPlanSelector) ||
              {})}
            faqs={
              intakePage?.faqs?.map((faq) => faq as ComponentContentFaq) || []
            }
            isLoading={isLoading || isPricingLoading}
            onPlanSelected={onPlanSelected}
            subscriptionPlans={subscriptionPlans || []}
            testimonials={
              intakePage?.testimonials?.map(
                (t) => t as ComponentContentTestimonial
              ) || []
            }
          />
        )}
        {!isPageLoading && slug === IntakePageSlug.FitnessProfile && (
          <IntakePageQuiz
            isLoading={isLoading}
            onComplete={onFitnessProfileCompleted}
            quiz={fitnessProfileQuiz || {}}
            slug={slug}
            stepNum={Number(stepNum)}
          />
        )}
      </div>
    </div>
  );
};

export default memo(IntakePage);
