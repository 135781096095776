import { Auth0Result } from "auth0-js";
import { useAnalytics } from "contexts/AnalyticsContext";
import { useAuth } from "contexts/AuthContext";
import { useGeolocation } from "contexts/GeolocationContext";
import { useLoginUserMutation, User } from "graphql/rails-api";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocationState } from "utils/location";

const usePostLoginAnalytics = () => {
  const analytics = useAnalytics();

  const postLoginAnalytics = useCallback(
    (user: User) => {
      analytics.identify(
        user.id,
        {
          membership_active: !!user.isSubscribed
        },
        {
          integrations: {
            Intercom: {
              user_hash: user?.intercomHash || ""
            }
          }
        }
      );
    },
    [analytics]
  );

  const postSignupAnalytics = useCallback(() => {
    analytics.track("Account Created", {
      source: "web-app"
    });
  }, [analytics]);

  return {
    postLoginAnalytics,
    postSignupAnalytics
  };
};

const usePostLogin = (isOsteoboost = false) => {
  const { state } = useLocation();
  const [loginUser] = useLoginUserMutation({
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  });
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth();
  const { postLoginAnalytics } = usePostLoginAnalytics();
  const geolocation = useGeolocation();

  const onLogin = useCallback((authResult?: Auth0Result) => {
    const accessToken = authResult?.accessToken || getAccessTokenSilently();

    console.log(`JRY DEBUG --> usePostLogin --> inside onLogin -->`, {
      accessToken
    });

    loginUser({
      context: {
        clientName: "rails-api",
        headers: { Authorization: "Bearer " + accessToken }
      },
      variables: {
        isOsteoboost,
        location: {
          ipAddress: geolocation?.ip || "",
          country: geolocation?.country || "",
          countryCode: geolocation?.country_code || "",
          region: geolocation?.region || "",
          city: geolocation?.city || "",
          postal: geolocation?.postal || "",
          latitude: geolocation?.latitude || 0,
          longitude: geolocation?.longitude || 0,
          timezone: geolocation?.timezone || ""
        }
      }
    })
      .then((result) => {
        const user = result.data?.loginUser as User;
        console.log(`JRY DEBUG --> usePostLogin --> loginUser.then() -->`, {
          result,
          user
        });

        if (user) {
          const returnTo = (state as LocationState)?.returnTo || undefined;
          const openOverlay =
            (state as LocationState)?.openOverlay || undefined;

          postLoginAnalytics(user);

          console.log(
            `JRY DEBUG --> usePostLogin --> loginUser.then() --> user exists`,
            { result, user, returnTo, openOverlay }
          );
          if (returnTo) {
            console.log(
              `JRY DEBUG --> usePostLogin --> loginUser.then() --> user exists --> returnTo`,
              { result, user, returnTo, openOverlay }
            );
            navigate(returnTo, {
              replace: true,
              state: {
                openOverlay: openOverlay
              } as LocationState
            });
          } else {
            navigate("/dashboard");
          }
        }
      })
      .catch((err) => {
        console.log(`JRY DEBUG --> usePostLogin --> loginUser.error() -->`, {
          err
        });
      })
      .finally(() => {
        console.log(
          `JRY DEBUG --> usePostLogin --> loginUser.finally() --> ????`
        );
      });
  }, []);

  return { onLogin };
};

export { usePostLogin, usePostLoginAnalytics };
