import { useAuth } from "contexts/AuthContext";
import {
  orderRoute,
  OsteoboostOrderSlug
} from "pages/order/OsteoboostOrderPage";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useOsteoboostOrderRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { isAuthenticated } = useAuth();

  console.log(`JRY DEBUG -- useOsteoboostOrderRoute --> `, {
    state,
    location,
    isAuthenticated
  });

  useEffect(() => {
    // The user is unauthenticated and does not have a complete order
    if (!isAuthenticated) {
      console.log(
        `JRY DEBUG --> useOsteoboostOrderRoute --> useEffect --> navigate --> /order/login`,
        { location, isAuthenticated }
      );

      // Allow some routes to not be redirected to login when unauthenticated
      if (location.pathname === orderRoute(OsteoboostOrderSlug.Signup)) return;

      // If we're not authenticated then we should show the login page
      // When login completes, we redirect to payments
      navigate(
        { pathname: orderRoute(OsteoboostOrderSlug.Login) },
        {
          replace: true,
          state: {
            from: location.pathname,
            returnTo: orderRoute(OsteoboostOrderSlug.Payment)
          }
        }
      );
    }
    // The user is authenticated.
    else {
      const returnTo = state?.returnTo;
      // Avoid redirecting if the user is navigating back
      if (state?.from && !returnTo && location.pathname === state.from) {
        console.log(
          `JRY DEBUG --> useOsteoboostOrderRoute --> useEffect --> AUTHENTICATED BACK NAVIGATION`,
          { location, isAuthenticated, state }
        );
        return;
      }

      // If the user is authenticated and landing directly on /order,
      // redirect them to the beginning of the order flow
      if (location.pathname === orderRoute(OsteoboostOrderSlug.Root)) {
        navigate(
          { pathname: orderRoute(OsteoboostOrderSlug.Payment) },
          { replace: true }
        );
        return;
      }

      // Redirect to payment page if the user is authenticated and on the login or signup page
      if (
        location.pathname === orderRoute(OsteoboostOrderSlug.Login) ||
        location.pathname === orderRoute(OsteoboostOrderSlug.Signup)
      ) {
        console.log(
          "JRY DEBUG --> useOsteoboostOrderRoute --> useEffect --> AUTHENTICATED and on Login/Signup",
          { location, state }
        );
        if (returnTo && location.pathname !== returnTo) {
          console.log(
            "JRY DEBUG --> useOsteoboostOrderRoute --> useEffect --> AUTHENTICATED and on Login/Signup --> returnTo defined",
            { returnTo, location, state }
          );
          navigate({ pathname: returnTo }, { replace: true });
        } else {
          console.log(
            "JRY DEBUG --> useOsteoboostOrderRoute --> useEffect --> AUTHENTICATED and on Login/Signup --> base redriect to PAYMENT",
            { location, state }
          );
          navigate(
            { pathname: orderRoute(OsteoboostOrderSlug.Payment) },
            { replace: true }
          );
          return;
        }
      }

      // For any sub-route in /order/:slug with a returnTo path, redirect there automatically
      if (returnTo && location.pathname !== returnTo) {
        console.log(
          `JRY DEBUG --> useOsteoboostOrderRoute --> useEffect --> AUTHENTICATED REDIRECT TO RETURNTO`,
          { location, isAuthenticated, state }
        );
        navigate({ pathname: returnTo }, { replace: true });
      }
    }
  }, [location.pathname]);
};
