import "./App.css";

import { ToastNotification } from "@getwellen/valesco";
import { AnalyticsProvider } from "contexts/AnalyticsContext";
import { AuthContext } from "contexts/AuthContext";
import {
  CurrentUserContext,
  CurrentUserProvider
} from "contexts/CurrentUserContext";
import { CustomerIoProvider } from "contexts/CustomerIoContext";
import {
  FeatureFlagContext,
  FeatureFlagProvider
} from "contexts/FeatureFlagContext";
import { GeolocationProvider } from "contexts/GeolocationContext";
import { createApolloClient } from "contexts/GraphqlContext";
import { ModalProvider } from "contexts/ModalContext";
import { MusicProvider } from "contexts/MusicContext";
import { OverlayOpenProvider } from "contexts/OverlayOpenContext";
import { PromoCodeProvider } from "contexts/PromoCodeContext";
import TRPCProvider from "contexts/TRPContext";
import { WorkoutProgramProvider } from "contexts/WorkoutProgramContext";
import LoadingPage from "pages/LoadingPage";
import React, { useEffect, useMemo, useState } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { BrowserRouter, useLocation } from "react-router-dom";

import { AuthProvider, GraphqlProvider } from "./contexts";
import Router from "./routes";

const ScrollToTop: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { state, pathname } = useLocation();
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  useEffect(() => {
    if (!state?.backgroundLocation && !isOverlayOpen) {
      window.scrollTo(0, 0);
    } else {
      setIsOverlayOpen(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (isOverlayOpen && !state?.backgroundLocation) setIsOverlayOpen(false);
  }, [state]);

  return <>{children}</>;
};

const App: React.FC = () => {
  const loader = useMemo(() => <LoadingPage />, []);

  return (
    <AnalyticsProvider>
      <GeolocationProvider>
        <AuthProvider loadingComponent={loader}>
          <AuthContext.Consumer>
            {({ getAccessTokenSilently, isLoading, loadingComponent }) =>
              isLoading ? (
                loadingComponent
              ) : (
                <GraphqlProvider
                  client={createApolloClient(getAccessTokenSilently())}
                >
                  <TRPCProvider>
                    <FeatureFlagProvider loadingComponent={loader}>
                      <FeatureFlagContext.Consumer>
                        {({ isLoading, loadingComponent }) =>
                          isLoading ? (
                            loadingComponent
                          ) : (
                            <CurrentUserProvider loadingComponent={loader}>
                              <CurrentUserContext.Consumer>
                                {({
                                  currentUserIsLoading,
                                  loadingComponent
                                }) =>
                                  currentUserIsLoading ? (
                                    loadingComponent
                                  ) : (
                                    <BrowserRouter>
                                      <CustomerIoProvider>
                                        <ScrollToTop>
                                          <SkeletonTheme
                                            baseColor="#BEBDB9"
                                            highlightColor="#EEECE7"
                                          >
                                            <ModalProvider>
                                              <WorkoutProgramProvider>
                                                <MusicProvider>
                                                  <OverlayOpenProvider>
                                                    <PromoCodeProvider>
                                                      <Router />
                                                    </PromoCodeProvider>
                                                  </OverlayOpenProvider>
                                                </MusicProvider>
                                              </WorkoutProgramProvider>
                                            </ModalProvider>
                                          </SkeletonTheme>
                                          <ToastNotification />
                                        </ScrollToTop>
                                      </CustomerIoProvider>
                                    </BrowserRouter>
                                  )
                                }
                              </CurrentUserContext.Consumer>
                            </CurrentUserProvider>
                          )
                        }
                      </FeatureFlagContext.Consumer>
                    </FeatureFlagProvider>
                  </TRPCProvider>
                </GraphqlProvider>
              )
            }
          </AuthContext.Consumer>
        </AuthProvider>
      </GeolocationProvider>
    </AnalyticsProvider>
  );
};

export default App;
