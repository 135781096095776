import { Radio, SvgDescription } from "@getwellen/valesco";
import {
  DefaultSizingForm,
  sizeRename,
  SizingKey,
  useOsteoboostOrder
} from "contexts/OsteoboostOrderContext";
import { type SizingFormType } from "contexts/OsteoboostOrderContext";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

import { FormStepButtons, FormStepChildProps } from "./FormStep";

type SizingFormProps = FormStepChildProps & {
  description: string;
};

export const SizingForm: React.FC<SizingFormProps> = ({
  description,
  showBack,
  onBack,
  onSubmit
}: SizingFormProps) => {
  const { isLoading, products, order, updateOrder } = useOsteoboostOrder();
  const { control, getValues, isValid } = useSizingForm(order[SizingKey]);

  // CALLBACKS
  const handleSubmit = useCallback(() => {
    const values = getValues();
    updateOrder(SizingKey, values, onSubmit);
  }, [onSubmit]);

  return (
    <form onSubmit={handleSubmit}>
      <SvgDescription
        containerStyles="mb-8"
        description={description}
        descriptionStyles="inline-block flex-shrink"
        svgStyles="inline-block flex-shrink-0 -mx-5 h-40 w-40"
      />
      <div className="mb-8 grid grid-cols-1 gap-3">
        <span className="block text-sm font-semibold text-cello-500">
          Available sizes
        </span>
        <ul className="grid grid-rows-4 gap-3">
          {products.map((option) => (
            <li key={option.id}>
              <Controller
                control={control}
                name="id"
                render={({ field: { onChange, value } }) => (
                  <Radio
                    checked={value === option.id}
                    label={`${option.name} | ${sizeRename[option.size]}`}
                    onChange={onChange}
                    value={option.id}
                  />
                )}
                rules={{ required: true }}
              />
            </li>
          ))}
        </ul>
      </div>

      <FormStepButtons
        isValid={isValid}
        loading={isLoading}
        onBack={onBack}
        onNext={handleSubmit}
        showBack={showBack}
      />
    </form>
  );
};

const useSizingForm = (formValues = DefaultSizingForm) => {
  const {
    control,
    watch,
    formState: { errors, isDirty, isValid },
    getValues
  } = useForm<SizingFormType>({
    mode: "onChange",
    defaultValues: formValues
  });

  return {
    control,
    errors,
    getValues,
    isDirty,
    isValid,
    watch
  };
};
