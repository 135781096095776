import { IconButton } from "@getwellen/valesco";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { useOsteoboostOrder } from "contexts/OsteoboostOrderContext";
import { OrderState } from "graphql/rails-api";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { orderRoute, OsteoboostOrderSlug } from "./OsteoboostOrderPage";

type FormStepProps = {
  step: number;
  total: number;
  title: string;
  description: string;
  subtitle?: string;
  showBack?: boolean;
  onBack?: () => void;
  onNext?: () => void;
  children: (props: FormStepChildProps) => React.ReactElement;
};

export interface FormStepChildProps {
  onSubmit: () => void;
  onBack: () => void;
  showBack: boolean;
}

// FormStep is a refactor of components/quiz/Quiz.tsx to be used in the
// OsteoboostOrderPage.
//
// It would be meaningful to reconcile the two divergent components as future
// work.
export const FormStep: React.FC<FormStepProps> = ({
  step,
  total,
  title,
  description,
  subtitle = "",
  showBack = true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onBack = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onNext = () => {},
  children
}) => {
  const StepString = `Step ${step} of ${total}`;

  const navigate = useNavigate();
  const { order } = useOsteoboostOrder();

  // EFFECTS

  // For any form step.. if the order is submitted, we need to redirect to order/compelete immediately
  useEffect(() => {
    if (order.state === OrderState.Submitted) {
      navigate(orderRoute(OsteoboostOrderSlug.Complete), { replace: true });
    }
  }, [order]);

  // CALLBACKS
  const onBackCallback = useCallback(() => {
    if (showBack) {
      onBack();
    }
  }, [showBack, onBack]);

  const onNextCallback = useCallback(() => {
    onNext();
  }, [onNext]);

  // Clone the child element and pass the props
  const childProps = {
    onSubmit: onNextCallback,
    onBack: onBackCallback,
    showBack
  };

  return (
    <div className="min-h-screen w-full px-4 pb-24 pt-16 md:m-auto md:mt-10 md:pb-72 md:pt-12 md:w-[750px]">
      <p className="mb-3 font-accent text-lg font-normal uppercase leading-tight">
        {StepString}
      </p>
      <h1 className="mb-4 font-display text-4xl">{title}</h1>
      {description && <p className="mb-10 text-lg">{description}</p>}
      {subtitle && <p className="mb-12 text-sm">{subtitle}</p>}

      {children(childProps)}
    </div>
  );
};
type FormStepButtonProps = {
  loading: boolean;
  showBack: boolean;
  isValid: boolean;
  onBack: () => void;
  onNext: () => void;
};
export const FormStepButtons: React.FC<FormStepButtonProps> = (props) => {
  const { loading, showBack, isValid, onBack, onNext } = props;
  const parentLayout = "mt-auto flex flex-row justify-between";
  const justifyShowBack = !showBack ? "justify-end" : "";
  const disableNextButton = loading || !isValid;
  return (
    <div className={twMerge(parentLayout, justifyShowBack)}>
      {showBack && (
        <IconButton action="secondary" onClick={onBack} variant="subtle">
          <ArrowLeftIcon className="mr-3 size-6" />
          Back
        </IconButton>
      )}
      <IconButton
        action="secondary"
        disabled={disableNextButton}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          onNext();
        }}
        type="submit"
        variant="bold"
      >
        Next
        <ArrowRightIcon className="ml-3 size-6" />
      </IconButton>
    </div>
  );
};
