import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEnv } from "contexts/EnvContext";
import { useGetCurrentUserStripeSetupIntentQuery } from "graphql/rails-api";
import React, { createContext, useContext, useState } from "react";
import { stripeElementsOptions } from "utils/stripe";

interface PaymentContext {
  stripeClientSecret?: string;
  paymentLoading: boolean;
}

const defaultState: PaymentContext = {
  stripeClientSecret: undefined,
  paymentLoading: false
};

const PaymentContext = createContext<PaymentContext>(defaultState);

const PaymentProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const {
    data: stripeSetupIntentData,
    loading: paymentLoading,
    error
  } = useGetCurrentUserStripeSetupIntentQuery({
    context: { clientName: "rails-api" },
    fetchPolicy: "no-cache"
  });

  const { STRIPE_API_KEY } = useEnv();
  const [stripePromise] = useState(() => loadStripe(STRIPE_API_KEY || ""));

  const clientSecret =
    stripeSetupIntentData?.getCurrentUserStripeSetupIntent.clientSecret;

  console.log(
    "JRY DEBUG --> PaymentProvider --> stripeSetupIntent, loading, error ",
    { stripeSetupIntentData, paymentLoading, error }
  );

  return (
    <PaymentContext.Provider
      value={{
        stripeClientSecret: clientSecret,
        paymentLoading
      }}
    >
      {!paymentLoading && clientSecret && (
        <Elements
          options={{
            ...stripeElementsOptions,
            clientSecret: clientSecret,
            mode: undefined
          }}
          stripe={stripePromise}
        >
          {children}
        </Elements>
      )}
    </PaymentContext.Provider>
  );
};

const usePayment = () => {
  const context = useContext(PaymentContext);

  if (!context)
    throw new Error("usePayment must be used within PaymentProvider");

  return context;
};

export { PaymentContext, PaymentProvider, usePayment };
