import { Checkbox, Select, TextInput } from "@getwellen/valesco";
import {
  type BillingAddressFormType,
  BillingAddressKey,
  DefaultBillingAddressForm,
  Order,
  ShippingAddressKey,
  useOsteoboostOrder
} from "contexts/OsteoboostOrderContext";
import usePrevious from "hooks/usePrevious";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  errorHelpText,
  stateOptions,
  validatePhoneNumber,
  validateZipcode
} from "utils/forms";

import { FormStepButtons, FormStepChildProps } from "./FormStep";

const countryOptions = [{ label: "United States", value: "US" }];

type BillingAddressFormProps = FormStepChildProps;

const getCurrentFormValues = (order: Order) => {
  const billing = order[BillingAddressKey];
  const shipping = order[ShippingAddressKey];
  if (billing.billingSameAsShipping) {
    return {
      ...billing,
      ...shipping
    };
  }
  return billing;
};

export const BillingAddressForm = ({
  showBack = true,
  onBack,
  onSubmit
}: BillingAddressFormProps) => {
  const { isLoading, order, updateOrder } = useOsteoboostOrder();
  const initValues = getCurrentFormValues(order);
  const { control, watch, reset, getValues, isValid, errors } =
    useBillingAddressForm(initValues);

  const billingSameAsShipping = watch("billingSameAsShipping");
  const prevBillingSameAsShipping = usePrevious(billingSameAsShipping);

  // CALLBACKS
  const handleSubmit = useCallback(() => {
    const values = getValues();
    updateOrder(BillingAddressKey, values, onSubmit);
  }, [onSubmit]);

  // EFFECTS
  useEffect(() => {
    if (billingSameAsShipping) {
      const shipping = order[ShippingAddressKey];
      reset({
        ...shipping,
        billingSameAsShipping: true
      });
      const billing = order[BillingAddressKey];
      updateOrder(
        BillingAddressKey,
        { ...billing, billingSameAsShipping: true },
        undefined /* onSuccessfulUpdate */,
        false /* submitMutation */
      );
    } else {
      const billing = order[BillingAddressKey];
      if (prevBillingSameAsShipping) {
        reset({
          ...DefaultBillingAddressForm,
          billingSameAsShipping: false
        });
      }
      updateOrder(
        BillingAddressKey,
        { ...billing, billingSameAsShipping: false },
        undefined /* onSuccessfulUpdate */,
        false /* submitMutation */
      );
    }
  }, [billingSameAsShipping]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-8">
        <Controller
          control={control}
          name="billingSameAsShipping"
          render={({ field: { onChange, onBlur, value } }) => (
            <Checkbox
              checked={value}
              label="Billing address is the same as shipping address"
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="mb-8 grid grid-cols-1 gap-8">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-3">
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                disabled={billingSameAsShipping}
                error={!!errors.firstName}
                helpText={errorHelpText(errors.firstName)}
                label="First name*"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: !billingSameAsShipping || "First name is required"
            }}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                disabled={billingSameAsShipping}
                error={!!errors.lastName}
                helpText={errorHelpText(errors.lastName)}
                label="Last name*"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: !billingSameAsShipping || "Last name is required"
            }}
          />
        </div>
        <Controller
          control={control}
          name="company"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              disabled={billingSameAsShipping}
              label="Company"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Controller
          control={control}
          name="address1"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              disabled={billingSameAsShipping}
              error={!!errors.address1}
              helpText={errorHelpText(errors.address1)}
              label="Address / PO Box*"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
          rules={{ required: !billingSameAsShipping || "Address is required" }}
        />
        <Controller
          control={control}
          name="address2"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              disabled={billingSameAsShipping}
              label="Address/ Unit"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-3">
          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                disabled={billingSameAsShipping}
                error={!!errors.city}
                helpText={errorHelpText(errors.city)}
                label="City*"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            rules={{ required: !billingSameAsShipping || "City is required" }}
          />
          <Controller
            control={control}
            name="state"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                disabled={billingSameAsShipping}
                error={!!errors.state}
                helpText={errorHelpText(errors.state)}
                label="State*"
                onBlur={onBlur}
                onChange={onChange}
                options={stateOptions}
                value={value}
              />
            )}
            rules={{ required: !billingSameAsShipping || "State is required" }}
          />
        </div>
        <Controller
          control={control}
          name="zipcode"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              disabled={billingSameAsShipping}
              error={!!errors.zipcode}
              helpText={errorHelpText(errors.zipcode)}
              label="Zip code*"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
          rules={{
            required: !billingSameAsShipping || "Zip code is required",
            validate: validateZipcode
          }}
        />
        <Controller
          control={control}
          name="country"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              disabled={billingSameAsShipping}
              error={!!errors.country}
              helpText={errorHelpText(errors.country)}
              label="Country*"
              onBlur={onBlur}
              onChange={onChange}
              options={countryOptions}
              value={value}
            />
          )}
          rules={{ required: !billingSameAsShipping || "Country is required" }}
        />
        <Controller
          control={control}
          name="phone"
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              disabled={billingSameAsShipping}
              error={!!errors.phone}
              helpText={errorHelpText(errors.phone)}
              label="Phone*"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
          rules={{
            required: !billingSameAsShipping || "Phone number is required",
            validate: validatePhoneNumber
          }}
        />
      </div>

      <FormStepButtons
        isValid={isValid}
        loading={isLoading}
        onBack={onBack}
        onNext={handleSubmit}
        showBack={showBack}
      />
    </form>
  );
};

// useBillingAddressForm handles form input and validation for the BillingAddress component
const useBillingAddressForm = (formValues = DefaultBillingAddressForm) => {
  const {
    control,
    getValues,
    reset,
    formState: { isValid, errors },
    watch,
    trigger
  } = useForm<BillingAddressFormType>({
    mode: "onBlur",
    defaultValues: formValues
  });

  return {
    control,
    isValid,
    errors,
    watch,
    reset,
    trigger,
    getValues
  };
};
